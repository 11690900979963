import * as React from "react"
import styled from 'styled-components'
import {Page} from "../shared/Page/Page";


const Container = styled.div`

`


const UseCases = () => {
    return (
        <Page constrained>
            <Container>

            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                 fill="currentColor" className={"bottom-link-chevron"}>
        <path
            d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z"/>
    </svg>)
}

export default UseCases
